<template>
        <div class="blogpostContent" style="overflow-x: hidden;">
               <div class="blogpostBody">
<!--                   <mavon-editor-->
<!--                           class="md"-->
<!--                           :value="blogsss"-->
<!--                   :subfield = "prop.subfield"-->
<!--                   :defaultOpen = "prop.defaultOpen"-->
<!--                   :toolbarsFlag = "prop.toolbarsFlag"-->
<!--                   :editable="prop.editable"-->
<!--                   :scrollStyle="prop.scrollStyle"></mavon-editor>-->
                   <v-md-preview :text="blogsss" height="400px"></v-md-preview>
               </div>
            <br/>
            <br/>
            <div class="demo_line_02"><span style="color:greenyellow;">End</span></div>
            <br/>
            <br/>
            <div class="demo_line_03">
                <button class="zan" @click="dialogVisible=true">
                    <span>赞赏</span>
                </button>
            </div>
            <br/>

            <span style="font-size: 30px;font-family: inherit;color:grey;color: #ffffff"> 发表评论 </span>
            <br/>
            <br/>
            <br/>
            <br/>

            <div class="blog_message" style="background-color: #303133;width: 1300px">
                <div class="blog_main_message" style="width: 1280px;margin-left: 18px;color: #ffffff">
                    <!-- 文本域 -->
                    <textarea name="content" autofocus="autofocus" rows="6" cols="40" v-model="messageContent" id="mes"
                              wrap="hard" :placeholder="bakcContent" class="message_content" ></textarea>
                    <!-- 留言人和邮箱 -->
                    <ul>
                        <li>
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-rentou"></use>
                            </svg>
                            <input type="text" v-model="messageUser" placeholder="昵称" autocomplete="on" class="user_content">
                        </li>
                        <li>
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-youjian"></use>
                            </svg>
                            <input type="text" v-model="messageMail" placeholder="电子邮件" autocomplete="on" class="email_content">
                        </li>
                        <li class="submit_button">
                            <button  class="button_content" @click="addMessage()">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-fabu"></use>
                                </svg>提交
                            </button>
                        </li>
                    </ul>
                    <br/>
                    <br/>

                    <!-- 评论信息 -->
                    <div  class="concrete_content" v-for="(item,index) in commentList" :key="index">
                        <!-- 主留言 -->
                        <div class="main_content_img">
                            <img class="headTou"  :src="item.mainContent.commentUser=='FQZ'?'https://gitee.com/FQZS/photos/raw/master/img/blog/head.jpg':'http://img.fuqz.cc/head1.jpg'" alt="">
                            <div class="main_content">
                                <h4 v-if="item.mainContent.commentUser=='FQZ'" style="color: #ff8000">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-zhuren"></use>
                                    </svg>博主</h4>
                                <h4 v-if="item.mainContent.commentUser!='FQZ'">{{item.mainContent.commentUser}}</h4>
                                <span>{{item.mainContent.commentDt}}</span>
<!--                                <p class="main_content_name"><h4>{{item.mainContent.commentUser}}</h4>&nbsp;<span>{{item.mainContent.commentDt}}</span></p>-->
                                <p class="message-ping">{{item.mainContent.commentContent}}</p>
                                <p class="message_replay">  <a href="javascript:void(0)" @click="focusReply(item.mainContent.id,item.mainContent.commentUser)">回复</a></p>
                            </div>
                        </div>
                        <div class="vice_content_img" v-for="(values,index) in item.replyContent" :key="index">
                            <img class="vice_headTou" :src="values.replyUser=='FQZ'?'https://gitee.com/FQZS/photos/raw/master/img/blog/head.jpg':'http://img.fuqz.cc/head1.jpg'" alt="">
                            <div class="main_content">
                                <h4 v-if="values.replyUser=='FQZ'" style="color: #ff8000">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-zhuren"></use>
                                    </svg>博主
                                </h4>
                                <h4 v-if="values.replyUser!='FQZ'">{{values.replyUser}}</h4>
                                <h4 v-if="values.replyCommentUser=='FQZ'" style="color: #ff8000">
                                    @&nbsp;<svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-zhuren"></use>
                                </svg>博主
                                </h4>
                                <h4 v-if="values.replyCommentUser!='FQZ'"  style="color: #00B5AD">@&nbsp;{{values.replyCommentUser}}</h4>
                                <span>{{values.replyDt}}</span>
                                <p class="message-ping">{{values.replyContent}}</p>
                                <p class="message_replay"> <a href="javascript:void(0)" @click="focusReply(item.mainContent.id,values.replyUser)">回复</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <el-backtop style="width: 80px;height: 80px">
                <svg class="icon" aria-hidden="true" style="width:40px;height: 40px;margin:0">
                    <use xlink:href="#icon-huidaodingbu"></use>
                </svg>
            </el-backtop>

            <el-dialog
                    title="赞赏作者"
                    :visible.sync="dialogVisible"
                    width="25%">
                <img src="http://img.fuqz.cc/wechat.jpg" alt="" class="wechat" v-if="isshow">
                <img src="http://img.fuqz.cc/Alipay.jpg" alt="" class="Alipay" v-if="!isshow">
                <span slot="footer" class="dialog-footer">
                 <el-button class="Alipay_button"  @click="isshow=false">
                     <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-zhifubao1"></use>
                        </svg>支付宝</el-button>
                 <el-button class="wechat_button" @click="isshow=true">
                     <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-weixinzhifu1"></use>
                        </svg>微信</el-button>
                </span>
            </el-dialog>

        </div>
</template>

<script>
    export default {
        name: "blogpostContent",
        data(){
            return {
                /* 赞赏框 */
                dialogVisible:false,
                isshow:false,
                dialogconentLook:true,
                blogsss:"",
                messageContent:'',
                messageUser:'',
                messageMail:'',
                /* 留言板背景内容 */
                bakcContent:"请输入留言信息...",
                commentList:[],
                /* 回复用户和id*/
                id:0,
                replyUserName:''
            }
        },
        methods:{
            /* 获取文章*/
            async getaritle(){
                const res = await this.$home.findBlogByid({id:this.$route.params.id})
                this.blogsss = res.data.blogContent
            },
            /* 获取评论*/
            async findAllComment(){
                const res =  await this.$home.findAllBlogComment({id:this.$route.params.id})
                this.commentList = res.data
            },
            /* 添加评论 */
            async addMessage(){
                if(this.id==0){
                    if(this.messageMail=='' || this.messageUser=='' || this.messageMail==''){
                        return this.$message.warning("请将信息填写完整")
                    }
                    const res = await this.$home.addMianComment({
                        id:this.$route.params.id,
                        userName:this.messageUser,
                        content:this.messageContent,
                        userMail:this.messageMail
                    })
                    if(res.code!=100) return this.$message.error("留言失败！")
                    this.$message.success("留言成功,等待审核!")
                    this.messageUser =""
                    this.messageContent = ""
                    this.messageMail = ""
                }else{
                    if(this.messageMail=='' || this.messageUser=='' || this.messageMail==''){
                        return this.$message.warning("请将信息填写完整")
                    }
                    const res = await this.$home.addBlogreply({
                        mainId:this.id,
                        userName:this.messageUser,
                        content:this.messageContent,
                        userMail:this.messageMail,
                        replyName:this.replyUserName
                    })
                    if(res.code!=100) return this.$message.error("留言失败！")
                    this.$message.success("留言成功,等待审核!")
                    this.messageUser =""
                    this.messageContent = ""
                    this.messageMail = ""
                    this.bakcContent = "请输入留言信息..."
                }

            },
            focusReply(id,value){
                document.getElementById("mes").focus()
                this.bakcContent = "@:"+value
                this.replyUserName = value
                this.id = id
            }
        },
        computed: {
            prop () {
                let data = {
                    subfield: false,// 单双栏模式
                    defaultOpen: 'preview',//edit： 默认展示编辑区域 ， preview： 默认展示预览区域
                    editable: false,
                    toolbarsFlag: false,
                    scrollStyle: true
                }
                return data
            }
        },
        created() {
            /* 显示轮播图 */
            this.$store.commit("updateRotation",false)
            /* 隐藏左侧 */
            this.$store.commit("updateLeftShow",true)
            this.getaritle()
            this.findAllComment()
        }
    }
</script>


</style>
